
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  nextTick,
  watch,
} from "vue";
import { getDropdownsByType } from "@/api/dropdown.api";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { getPanel } from "@/api/encounter.api";
import { searchServicesV2 } from "@/api/services.api";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { searchList } from "@/api/claim.api";
import { searchPatientPayers } from "@/api/patientPayer.api";
import { selfPayer } from "../patientPayer/patientPayer.model";
import debounce from "lodash.debounce";
import useFilters from "@/modules/common/useFilters";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: string;
  description: string;
}

export interface Data {
  emptyListError?: string;
  expandedAll: boolean;
  items: any[];
  patientId: any;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  filter: {
    startDate: Date | string | null;
    endDate: Date | string | null;
    facility: [];
    currentResponsibleParty: [];
    status: [];
    claimStatus: [];
    globalSearch: string | null;
  };
  dict: {
    patientPayers: { name: string; payerId: string; subscriberId: string }[];
    facilities: { id: string }[];
    paymentTypes: [];
    adjustmentReasonCodes: any[];
    transactionTypes: [];
    encounterStatusCodes: StatusCode[];
    claimsStatusCodes: StatusCode[];
    insurancePaymentTypes: any[];
  };
}
export default defineComponent({
  name: "ServiceHistory",
  props: ["patientId"],
  components: { Multiselect, PaginationUi, DateFloatComponent },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const orderList = [
      {
        name: "Encounter ID",
        key: "encounter.encounterId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Service Date",
        key: "serviceDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Facility",
        key: "encounter.facility.name",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Service",
        key: "service",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Description",
        isFilter: true,
        key: "description",
        keyword: null,
      },
      {
        name: "Days Or Units",
        isFilter: true,
        key: "daysOrUnits",
        keyword: null,
      },
      {
        name: "Charge Per Unit",
        isFilter: true,
        key: "chargePerUnit",
        keyword: null,
      },
      {
        name: "Total Charges",
        key: "totalCharges",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Total Payments/Adjustments",
        key: "totalPaymentsAdjustments",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Balance Due",
        key: "balanceDue",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Current Responsible Party",
        key: "encounter.responsibleParty",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Encounter Status",
        key: "encounter.status",
        keyword: null,
        isFilter: true,
      },
      {
        name: "Active Claim Status",
        key: "claimStatus",
        keyword: null,
        isFilter: true,
        IsAdvancedOrderBy: true,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      expandedAll: false,
      patientId: props.patientId,
      items: [],
      orderBy: "default",
      IsAdvancedOrderBy: true,
      isDecr: false,
      filter: {
        startDate: null,
        endDate: null,
        facility: [],
        currentResponsibleParty: [],
        status: [],
        claimStatus: [],
        globalSearch: null,
      },
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        patientPayers: [],
        facilities: [],
        paymentTypes: [],
        adjustmentReasonCodes: [],
        transactionTypes: [],
        encounterStatusCodes: [],
        claimsStatusCodes: [],
        insurancePaymentTypes: [
          { id: 0, name: "Check" },
          { id: 1, name: "EFT" },
          { id: 2, name: "Virtual Card" },
        ],
      },
    });

    async function cleanFilter() {
      data.filter = {
        startDate: null,
        endDate: null,
        facility: [],
        currentResponsibleParty: [],
        status: [],
        claimStatus: [],
        globalSearch: null,
      };

      data.orderBy = "default";
      data.IsAdvancedOrderBy = true;
      orderList.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;
      let adjustmentReasonCodes = await getDropdownsByType({
        type: "Adjustment Types",
      });
      data.dict.adjustmentReasonCodes = adjustmentReasonCodes.data;
      data.dict.encounterStatusCodes = store.getters.allEncounterStatuses.sort(
        (a, b) => (a.description > b.description ? 1 : -1)
      );
      data.dict.claimsStatusCodes = store.getters.allClaimStatuses.sort(
        (a, b) => a.description.localeCompare(b.description)
      );

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;

      let request = {
        pageNumber: 1,
        pageSize: 1000,
        orderBy: ["name"],
        PatientId: props.patientId,
        ShowInactive: true,
      };

      // gets all patient payers by distinct payerId
      const res = await searchPatientPayers(request);
      res.data.forEach((item) => {
        if (!data.dict.patientPayers.find((x) => x.payerId === item.payerId)) {
          data.dict.patientPayers.push({
            name: item.name,
            payerId: item.payerId,
            subscriberId: item.subscriberIdNumber,
          });
        }
      });
      data.dict.patientPayers.unshift(selfPayer);

      await getAll();
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clearSearch() {
      await nextTick();
      await search();
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      data.expandedAll = false;
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        PatientId: props.patientId,
        StartDate: data.filter.startDate,
        EndDate: data.filter.endDate,
        Facility: data.filter.facility,
        CurrentResponsibleParty: data.filter.currentResponsibleParty,
        EncounterStatuses: data.filter.status,
        ClaimStatuses: data.filter.claimStatus,
        GlobalSearch: data.filter.globalSearch,
      };

      const res = await searchServicesV2(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;
      if (data.items.length == 0) {
        const currentFilters = Object.keys(data.filter).filter((f) => {
          return (
            data.filter[f] != undefined &&
            data.filter[f] !== "" &&
            data.filter[f] != null
          );
        });
        if (currentFilters.length > 0) {
          data.emptyListError =
            "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      } else {
        // merge payment lists
        for (let item of data.items) {
          item.combinedPaymentList = [
            ...item.paymentServiceItems,
            ...item.postedInsurancePayments,
          ];
        }
      }
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function viewEncounter(item) {
      router.push({ path: "/encounter/" + item.encounter.id });
    }

    async function viewClaimDetails(item) {
      const claims = await searchList({ encounterId: item.encounter.id });
      const claim = claims[0];
      let detailType = "/facilityClaimDetails";
      if (claim.encounterType == 1) detailType = "/professionalClaimDetails";
      router.push({ path: "/claim/" + claims[0].id + detailType });
    }

    function expand(item) {
      item.expanded = !item.expanded;
    }

    watch(
      () => data.expandedAll,
      async () => {
        data.items.forEach((item) => {
          item.expanded = data.expandedAll;
        });
      }
    );

    function getReversalType(item, service): string {
      if (item.reversedFromId) {
        // gets the full paymentServiceItem that was reversed
        let reversedFrom = service.paymentServiceItems.find(
          (el) => el.id == item.reversedFromId
        );

        // check original type, payment or adjustment
        if (reversedFrom.paymentExt.type == 0) {
          return "Payment Reversal";
        } else if (reversedFrom.paymentExt.type == 1) {
          return "Adjustment Reversal";
        }
      }

      return "Not a reversal.";
    }

    function getStatusHeader(code) {
      if (data.dict.encounterStatusCodes) {
        const status = data.dict.encounterStatusCodes.find((item) => {
          return item.code == code;
        });
        if (status) {
          return status.description;
        }
      }
      return code;
    }

    function getClaimStatusHeader(code) {
      const status = data.dict.claimsStatusCodes.find((item) => {
        return item.code == code;
      });
      if (status) {
        return status.description;
      }
      return "";
    }

    return {
      viewEncounter,
      viewClaimDetails,
      organizationId,
      clearSearch,
      data,
      orderList,
      debounceSearch,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      expand,
      getReversalType,
      getStatusHeader,
      getClaimStatusHeader,
    };
  },
});
